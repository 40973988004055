import React from 'react';
import { styled } from '@mui/material';

const StyledSkipLink = styled('a')(({ theme }) => ({
  position: 'absolute',
  top: -9999,
  left: -9999,
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  textDecoration: 'none',
  borderRadius: '0 0 12px 12px',
  zIndex: 9999,
  transition: 'all 0.3s ease',
  fontWeight: 500,
  '&:focus': {
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    outline: 'none',
    boxShadow: `0 4px 20px rgba(${theme.palette.mode === 'light' ? '0,0,0,0.1' : '255,255,255,0.1'})`,
  },
  '&:hover': {
    background: theme.palette.primary.dark,
  }
}));

const SkipLink = () => {
  return (
    <StyledSkipLink href="#main-content">
      Skip to main content
    </StyledSkipLink>
  );
};

export default SkipLink;
