import React from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/material';

const PageWrapper = styled(motion.div)(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  padding: theme.spacing(3),
  background: theme.palette.background.default,
}));

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.98,
    y: 10
  },
  in: {
    opacity: 1,
    scale: 1,
    y: 0
  },
  out: {
    opacity: 0,
    scale: 0.98,
    y: 10
  }
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5
};

const AnimatedPage = ({ children }) => {
  return (
    <PageWrapper
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {children}
    </PageWrapper>
  );
};

export default AnimatedPage;
