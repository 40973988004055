import { createTheme } from '@mui/material/styles';

// Custom color palette
const colors = {
  primary: {
    main: '#A5BE00',
    light: '#B8D000',
    dark: '#8FA300',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#1565c0',
    light: '#1976d2',
    dark: '#0d47a1',
    contrastText: '#FFFFFF'
  },
  background: {
    default: '#EBF4FA',
    paper: '#FFFFFF',
    neumorphic: '#EBF4FA'
  },
  text: {
    primary: '#2D3748',
    secondary: '#4A5568'
  },
  error: {
    main: '#E53E3E',
    light: '#FC8181',
    dark: '#C53030'
  },
  warning: {
    main: '#DD6B20',
    light: '#F6AD55',
    dark: '#C05621'
  },
  info: {
    main: '#3182CE',
    light: '#63B3ED',
    dark: '#2C5282'
  },
  success: {
    main: '#A5BE00',
    light: '#B8D000',
    dark: '#8FA300'
  }
};

// Neumorphic shadow configuration
const neumorphicShadows = {
  light: {
    boxShadow: '8px 8px 16px #d9e6f2, -8px -8px 16px #ffffff',
    pressed: 'inset 8px 8px 16px #d9e6f2, inset -8px -8px 16px #ffffff'
  },
  dark: {
    boxShadow: '8px 8px 16px #10141E, -8px -8px 16px #1C2538',
    pressed: 'inset 8px 8px 16px #10141E, inset -8px -8px 16px #1C2538'
  }
};

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    ...colors,
  },
  shape: {
    borderRadius: 12
  },
  shadows: [
    'none',
    neumorphicShadows.light.boxShadow,
    ...Array(23).fill(neumorphicShadows.light.boxShadow)
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          padding: '10px 20px',
          '&:not($contained)': {
            boxShadow: neumorphicShadows.light.boxShadow,
            backgroundColor: colors.background.neumorphic,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#e0ebf5'
            },
            '&:active': {
              boxShadow: neumorphicShadows.light.pressed
            }
          }
        },
        contained: {
          boxShadow: neumorphicShadows.light.boxShadow,
          '&:hover': {
            boxShadow: 'none'
          },
          '&:active': {
            boxShadow: neumorphicShadows.light.pressed
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadows.light.boxShadow,
          backgroundColor: colors.background.neumorphic,
          '&:hover': {
            boxShadow: '12px 12px 20px #d9e6f2, -12px -12px 20px #ffffff'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.background.neumorphic,
          '&.MuiPopover-paper': {
            boxShadow: neumorphicShadows.light.boxShadow
          }
        },
        elevation1: {
          boxShadow: neumorphicShadows.light.boxShadow
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: colors.background.neumorphic,
            borderRadius: '12px',
            '& fieldset': {
              borderColor: 'transparent'
            },
            '&:hover fieldset': {
              borderColor: colors.primary.main
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.primary.main
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
        },
        track: {
          borderRadius: 22 / 2,
          backgroundColor: '#E9EDF2'
        },
        thumb: {
          boxShadow: neumorphicShadows.light.boxShadow,
          backgroundColor: colors.background.neumorphic
        }
      }
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#B8D000',
      light: '#C9E100',
      dark: '#96AE00',
      contrastText: '#FFFFFF'
    },
    background: {
      default: '#161B26',
      paper: '#1A2132',
      neumorphic: '#1A2132'
    },
    text: {
      primary: '#E2E8F0',
      secondary: '#A0AEC0'
    }
  },
  shape: {
    borderRadius: 12
  },
  shadows: [
    'none',
    neumorphicShadows.dark.boxShadow,
    ...Array(23).fill(neumorphicShadows.dark.boxShadow)
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          padding: '10px 20px',
          '&:not($contained)': {
            boxShadow: neumorphicShadows.dark.boxShadow,
            backgroundColor: '#1A2132',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#212942'
            },
            '&:active': {
              boxShadow: neumorphicShadows.dark.pressed
            }
          }
        },
        contained: {
          boxShadow: neumorphicShadows.dark.boxShadow,
          '&:hover': {
            boxShadow: 'none'
          },
          '&:active': {
            boxShadow: neumorphicShadows.dark.pressed
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadows.dark.boxShadow,
          backgroundColor: '#1A2132',
          '&:hover': {
            boxShadow: '12px 12px 20px #10141E, -12px -12px 20px #1C2538'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1A2132',
          '&.MuiPopover-paper': {
            boxShadow: neumorphicShadows.dark.boxShadow
          }
        },
        elevation1: {
          boxShadow: neumorphicShadows.dark.boxShadow
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#1A2132',
            borderRadius: '12px',
            '& fieldset': {
              borderColor: 'transparent'
            },
            '&:hover fieldset': {
              borderColor: '#B8D000'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#B8D000'
            }
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
        },
        track: {
          borderRadius: 22 / 2,
          backgroundColor: '#2D3748'
        },
        thumb: {
          boxShadow: neumorphicShadows.dark.boxShadow,
          backgroundColor: '#1A2132'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        .ql-toolbar, .ql-container {
          border-color: rgba(255, 255, 255, 0.23) !important;
          background-color: #1A2132 !important;
        }
        .ql-toolbar {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
        .ql-container {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        .ql-toolbar .ql-stroke {
          stroke: #E2E8F0 !important;
        }
        .ql-toolbar .ql-fill {
          fill: #E2E8F0 !important;
        }
        .ql-toolbar .ql-picker {
          color: #E2E8F0 !important;
        }
        .ql-toolbar button:hover .ql-stroke,
        .ql-toolbar button:focus .ql-stroke,
        .ql-toolbar button.ql-active .ql-stroke {
          stroke: #B8D000 !important;
        }
        .ql-toolbar button:hover .ql-fill,
        .ql-toolbar button:focus .ql-fill,
        .ql-toolbar button.ql-active .ql-fill {
          fill: #B8D000 !important;
        }
        .ql-toolbar .ql-picker-label:hover,
        .ql-toolbar .ql-picker-label.ql-active {
          color: #B8D000 !important;
        }
        .ql-editor {
          color: #E2E8F0 !important;
        }
        .ql-editor.ql-blank::before {
          color: rgba(255, 255, 255, 0.6) !important;
        }
      `
    }
  }
});

export { lightTheme, darkTheme };
